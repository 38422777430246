const ArrowRightIcon = ({ className = "" }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={17}
			height={12}
			fill="none"
			viewBox="0 0 17 12"
			className={className}
		>
			<path
				stroke="#2BB792"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M1.196 6.318h14.108M10.601 1.667l4.703 4.651-4.703 4.65"
			/>
		</svg>
	);
};

export default ArrowRightIcon;
