/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/self-closing-comp */
import styled from "styled-components";
import homepageData from "cms/homepage-data";
import { Close, CloseRounded } from "@material-ui/icons";
import { useEffect, useLayoutEffect, useState } from "react";
import TopBannerImg from "assets/svgs/TopBannerImage";
import CloseIcon20 from "assets/svgs/CloseIcon20";
import TopBannerMobileImg from "assets/svgs/TopBannerMobileImg";
import { Button, IconButton, Modal } from "@material-ui/core";
import HustleHaiDesignElement from "assets/svgs/HustleHaiDesignElement";
import ArrowRightIcon from "../../assets/svgs/ArrowRightIcon";
import { getUrlPath } from "../../utils/helpers";
import { mediaMaxWidth } from "../../utils/mediaQuery";
import useIsDesktop from "../../hooks/useIsDesktop";

const { homepage } = homepageData || {};

export const BANNER_ID = "announcement-banner";
const TopBanner = ({ source, onVisibilityChanged, isBranding }) => {
	const [isVisible, setVisible] = useState(false);
	const [openVideoModal, setOpenVideoModal] = useState(false);
	const isDesktop = useIsDesktop();

	const renderVideoModal = () => (
		<Modal open={openVideoModal} onClose={() => setOpenVideoModal(false)}>
			<ModalHolder>
				<iframe
					width={isDesktop ? "832" : "360"}
					height={isDesktop ? "468" : "230"}
					src="https://www.youtube.com/embed/VfRraeV8HmE?si=tVLmF-rXtNu4nOWI&autoplay=1"
					title="YouTube video player"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					referrerPolicy="strict-origin-when-cross-origin"
					allowfullscreen
				></iframe>
				<IconButton
					onClick={() => {
						setOpenVideoModal(false);
					}}
					style={{
						position: "absolute",
						top: "-50px",
						right: `${isDesktop ? 0 : "-45px"}`,
					}}
				>
					<CloseRounded style={{ color: "#fff" }} />
				</IconButton>
			</ModalHolder>
		</Modal>
	);

	const handleBannerClick = () => {
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Top banner click", {
				page: getUrlPath(),
				Source: source,
				actionType: "Banner",
				section: "Top Banner",
				bannerType: "New city launch banner",
			});
		});
	};

	useLayoutEffect(() => {
		try {
			const key =
				homepage?.data?.attributes?.topBannerTitle +
					homepage?.data?.attributes?.topBannerBlogUrl || "hustle_hai";
			const hiddenBanners = JSON.parse(
				localStorage.getItem(HIDDEN_BANNERS_KEY) || "[]"
			);
			setVisible(!hiddenBanners.includes(key));
			setVisible(!hiddenBanners.includes("hustle_hai"));
		} catch (e) {
			console.error(e);
		}
	}, []);

	const handleReadMoreClick = (e) => {
		e.preventDefault();
		e.stopPropagation();
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Top banner click", {
				page: getUrlPath(),
				Source: source,
				actionType: "CTA Click",
				section: "Top Banner",
				cta: "Learn More",
				bannerType: "New city launch banner",
			});
		});

		window.open(homepage?.data?.attributes?.topBannerBlogUrl, "_blank");
	};

	const handleHustleBannerClose = () => {
		const hiddenBanners = JSON.parse(
			localStorage.getItem(HIDDEN_BANNERS_KEY) || "[]"
		);
		hiddenBanners.push("hustle_hai");
		localStorage.setItem(HIDDEN_BANNERS_KEY, JSON.stringify(hiddenBanners));
		setVisible(false);
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Top banner close click", {
				Source: "Homepage",
				section: "Top Banner",
			});
		});
	};

	const handleBannerClose = () => {
		try {
			const key =
				homepage?.data?.attributes?.topBannerTitle +
				homepage?.data?.attributes?.topBannerBlogUrl;
			const hiddenBanners = JSON.parse(
				localStorage.getItem(HIDDEN_BANNERS_KEY) || "[]"
			);
			hiddenBanners.push(key);
			localStorage.setItem(HIDDEN_BANNERS_KEY, JSON.stringify(hiddenBanners));
			setVisible(false);
		} catch (e) {
			console.error(e);
		}
	};

	const HIDDEN_BANNERS_KEY = "u88504"; // random key

	useEffect(() => {
		onVisibilityChanged?.();
	}, [isVisible]);
	if (!isVisible) return <div id={BANNER_ID}></div>;
	if (isBranding) {
		return (
			<div>
				<HomePageHustleBanner id={BANNER_ID}>
					<div className="mx-auto flex w-full max-w-screen-xl px-0 md:px-5 ">
						<div className="self-end">
							{!isDesktop ? <TopBannerMobileImg /> : <TopBannerImg />}
						</div>
						<div className="relative flex w-full flex-col justify-between md:flex-row">
							<div className="ml-2 mt-0 md:ml-4 md:mt-1.5">
								<div className="relative w-[180px] md:w-[455px]">
									<p className="m-0 p-0 text-[16px] font-semibold leading-6 text-[#fff] md:w-full md:text-[24px] md:leading-9">
										Career mein{" "}
										<span className="text-[#FFD166] text-[]">
											{" "}
											struggle
										</span>{" "}
										nahi hustle karo
									</p>
									<div className="absolute right-[-10px] top-[-5px] md:right-[-10px] md:top-0">
										<HustleHaiDesignElement />
									</div>
								</div>
								{!isDesktop ? (
									<p className="m-0 p-0 text-[14px] leading-[20px] text-[#fff]">
										#ApnaHustleChalRahaHai
									</p>
								) : (
									<p className="mb-0 text-[16px] leading-6 text-[#fff]">
										Recognizing heroes who chase their dreams with
										relentless determination. #ApnaHustleChalRahaHai
									</p>
								)}
							</div>
							<div
								className="mb-4 ml-2 mr-[38px] mt-3 w-[109px] cursor-pointer self-start rounded bg-[#fff] text-center  md:mb-0 md:mt-0 md:w-[117px] md:self-center"
								onClick={() => {
									setOpenVideoModal(true);
									import("../../utils/Mixpanel").then((Mixpanel) => {
										Mixpanel.default.track(
											"Top banner watch video click",
											{ section: "Top Banner", Source: "Homepage" }
										);
									});
								}}
								aria-hidden
							>
								<p className="m-0 py-[6px] text-[14px] font-semibold leading-5 text-[#172B4D] md:py-[9px]  ">
									Watch video{" "}
								</p>
							</div>
							<div
								className="absolute right-[16px] top-[0px] cursor-pointer md:right-0 md:top-[42%]"
								onClick={handleHustleBannerClose}
								aria-hidden
							>
								<CloseIcon20 />
							</div>
						</div>
					</div>
				</HomePageHustleBanner>
				{openVideoModal && renderVideoModal()}
			</div>
		);
	}

	return (
		<HomePageTopBanner id={BANNER_ID} onClick={handleBannerClick}>
			<div className="relative w-full max-w-screen-xl px-4">
				<div id="banner-title">
					<div
						dangerouslySetInnerHTML={{
							__html: homepage?.data?.attributes?.topBannerTitle || "",
						}}
					/>
					<a
						onClick={(e) => handleReadMoreClick(e)}
						id="banner-read-more-link"
						href={homepage?.data?.attributes?.topBannerBlogUrl}
						className="flex items-center"
					>
						Check out now{" "}
						<span id="arrow-right-icon">
							<ArrowRightIcon className="[&>path]:stroke-[#1f8268]" />{" "}
						</span>
					</a>
				</div>

				<span
					onClick={handleBannerClose}
					className="absolute bottom-0 right-4 top-0 m-auto flex h-6 w-6 items-center justify-center rounded-full bg-white md:top-0"
					id="cross-icon"
					aria-hidden
				>
					<Close className="text-[#1F8268]" fontSize="small" />
				</span>
			</div>
		</HomePageTopBanner>
	);
};

export default TopBanner;

const HomePageHustleBanner = styled.div`
	background-color: #190a28;
	padding: 12px 0 0 0;
	display: flex;
	/* align-items:center; */
	${mediaMaxWidth.medium`
		padding: 16px  0 0 0;
	`}
`;

const HomePageTopBanner = styled.div`
	display: block;
	background: #fff9db;
	min-height: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-bottom: 1px solid #e18d00;

	br {
		display: none;

		${mediaMaxWidth.small`
			display: block;
		`}
	}

	${mediaMaxWidth.medium`
		padding: 6px 20px;
		min-height: 54px;
	`}

	div#banner-title {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		font-size: 16px;
		font-weight: 500;
		text-align: center;
		line-height: 1.7;
		margin-right: 30px;

		${mediaMaxWidth.medium`
			font-size: 10px;
		`}
	}

	a#banner-read-more-link {
		margin: 0 0 0 11px;
		font-size: 16px;
		font-weight: 600;
		text-align: center;
		cursor: pointer;
		color: #165f4d;

		&:hover {
			text-decoration: underline;
		}

		${mediaMaxWidth.medium`
			font-size: 10px;
			margin-left: 2px;
		`}

		#arrow-right-icon {
			flex-grow: 0;
			margin: 4px 0 3px 12px;
			object-fit: contain;

			${mediaMaxWidth.medium`
				margin-left: 0px;
			`}

			svg {
				width: 17px;
				height: 12px;

				${mediaMaxWidth.medium`
					width: 11;
  					height: 8px;
				`}
			}
		}
	}
	#cross-icon {
		cursor: pointer;
	}
`;

const ModalHolder = styled.div`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	position: absolute;
	width: 832px;
	outline-style: none;
	box-shadow: none;
	border-color: transparent;
	${mediaMaxWidth.medium`
	    width:320px;
	    transform: translate(-56%);
		top: 28%;
	`}
`;
