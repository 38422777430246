/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const HustleHaiDesignElement = (props) => (
	<svg
		width="23"
		height="24"
		viewBox="0 0 23 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
        {...props}
	>
		<path
			d="M1.52612 10.4694L5.69584 3.2472"
			stroke="#FFD166"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M3.19409 15.458L19.444 6.07614"
			stroke="#FFD166"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M4.41504 21.2504L12.7545 21.2504"
			stroke="#FFD166"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>
);

export default HustleHaiDesignElement;
